<template>
  <main class="media-page page">
    <BreadcrumbsComponent title="Медиа" :links="links" />
    <div class="container-padding">
      <div class="media-page__inner">
        <div class="media-page__filter">
          <SelectComponent
            title="Категория"
            label-name="title"
            placeholder="Все"
            v-model="form.filter.value"
            :options="[{ id: 1, title: 'Фотогалееря' }]"
          />
          <div class="media-page__actions">
            <button class="btn btn--white btn--lg">
              <span>Поиск</span>
            </button>
            <button class="btn btn--main btn--lg">
              <span>Сбросить</span>
            </button>
          </div>
        </div>
        <div class="media-page__counter">
          <span> Найдено 560 записей </span>
        </div>
        <ul class="media-page__list">
          <li v-for="item in 12" :key="item">
            <router-link :to="{ name: 'newsItem', params: { id: item } }">
              <CardComponent media />
            </router-link>
          </li>
        </ul>
        <PaginationComponent :page="1" :total="12" />
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import CardComponent from "components/CardComponent.vue";
import PaginationComponent from "components/PaginationComponent.vue";
import SelectComponent from "components/inputs/select/index.vue";

export default {
  name: "MediaPage",

  data() {
    return {
      form: {
        filter: {
          value: { id: 1, title: "Фотогалееря" },
          errors: [],
        },
      },
    };
  },

  computed: {
    links() {
      return [
        {
          title: "Пресс-центр",
          to: { name: "media" },
        },
        {
          title: "Медиа",
          to: { name: "media" },
        },
      ];
    },
  },
  components: {
    SelectComponent,
    PaginationComponent,
    BreadcrumbsComponent,
    CardComponent,
  },
};
</script>

<style lang="stylus">
.media-page {
  padding 15px 0 60px 0
  &__inner {
    display grid
    grid-gap 40px
    +below(600px) {
      gap 20px
    }
  }

  &__filter {
    display flex
    align-items: flex-end;
    gap 40px
    +below(600px) {
      align-items flex-start
      flex-direction column
      gap 20px
      .select {
        width: 100%;
      }
    }
  }

  &__actions {
    display flex
    gap 15px
  }

  &__counter {
    font-family: Inter;
    font-size: 0.875em
    font-style: normal;
    font-weight: 400;
    line-height: 22px
    color var(--main-1-dark-50)
  }

  &__list {
    display grid
    grid-template-columns repeat(4, 1fr)
    gap 40px
    +below(1300px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(900px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(600px) {
      gap 20px
    }
    +below(550px) {
      grid-template-columns repeat(1, 1fr)
    }
  }
}
</style>
